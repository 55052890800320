<template>
  <div class="modal modal_wrap" >
    <div class="modal_popup">
      <p class="text text_white">Are you sure you want to cancel this order?
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="close"><svg-icon name="solid/step-backward" /> Back</button>
        <button class="btn btn_transparent" @click="cancelOrder"><svg-icon name="solid/check" />  Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
  import router from 'vue-router';
  import {mapGetters} from 'vuex';

  export default {
    name: "CancelOrder",

    methods: {
      close(){
        this.$store.commit('orderPreview/cancel', false);
      },

      cancelOrder(){
        let id = this.$store.getters['orderPreview/id'];
        this.$store.dispatch('orderPreview/cancelOrder', id);
        this.$store.commit('orderPreview/cancel', false);
      }
    },
  }
</script>

<style scoped lang="scss">
  .justify-content-between {
    display: flex;
  }
</style>
